@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Base scrollbar styles */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(17, 24, 39, 0.7);
  backdrop-filter: blur(10px);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, rgba(139, 92, 246, 0.3), rgba(56, 189, 248, 0.3));
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, rgba(139, 92, 246, 0.5), rgba(56, 189, 248, 0.5));
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
  background: #111827;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.perspective-1000 {
  perspective: 1000px;
}

/* Add Solana Wallet Adapter button styles */
.wallet-adapter-button {
  @apply font-['Space_Grotesk'] tracking-wide;
}

.wallet-adapter-modal-wrapper {
  @apply bg-gray-900 !important;
}

.wallet-adapter-modal-button-close {
  @apply bg-gray-800 !important;
}

.wallet-adapter-modal-title {
  @apply text-white font-['Space_Grotesk'] !important;
}